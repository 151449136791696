import React, { useState } from "react"
import { useForm } from 'react-hook-form'
import Form, { FormGroup, Input, Select, Error, Textarea } from "../../ui/form"
import Button from '../../ui/button'
import ModalForm from "../../ui/modal-form";
import Text from '../../../components/ui/text'

const ProductForm = ({formActive,setFormActive,formTitle,formDesc,icon}) => {
    const [formSubmitted,setFormSubmitted] = useState(false)
    const [formError,setFormError] = useState(false)
    const [waiting,setWaiting] = useState(false)

    const { register, handleSubmit, errors } = useForm({
          mode: "onBlur"
      })

    let xhr = new XMLHttpRequest()

    xhr.addEventListener('load', () => {
      //console.log("Resp?")
      // update the state of the component with the result here

      if(xhr.responseText.length > 0){
        setFormError(false)
      }
      else{
        setFormError(true)
      }

      setWaiting(false)
      setFormSubmitted(true)
    })

    const juggleForm = (st) => {
        setFormActive(st)
    }

    const onSubmit = (data) => {
        //console.log(data)

        xhr.open('POST', 'https://garantitilbud.no/data.php')
        // send the request
        xhr.send(JSON.stringify(data))
        setWaiting(true)
    }

    const lang = "nb"

    return(
        <ModalForm setFormActive={juggleForm} formActive={formActive} formTitle={formTitle} icon={icon}>
          {!formSubmitted && !waiting && <Form onSubmit={handleSubmit(onSubmit)}>
                <Text>{formDesc}</Text>

                <Text>
                  Merk at bedriften må oppfylle følgende krav for at et tilbud på garanti skal kunne utstedes:
                  <ul style={{listStyle:'disc',paddingTop:'15px',paddingBottom:'15px'}}>
                    <li style={{marginLeft:'20px'}}>Minimum 3 års positiv drift i selskapet</li>
                    <li style={{marginLeft:'20px'}}>Ingen tidligere konkurser e.l.</li>
                    <li style={{marginLeft:'20px'}}>Ingen tidligere erstatningssaker</li>
                  </ul>
                </Text>

                <FormGroup mb="20px">
                    <Input
                        name="f1_name"
                        id="f1_name"
                        type="text"
                        placeholder={lang==="en"?"Your name *":"Bedriftens navn *"}
                        hover="2"
                        ref={register({ required: (lang==="en"?"Name is required":'Navn er påkrevd') })}
                    />
                    <Error>{errors.f1_name && errors.f1_name.message}</Error>
                </FormGroup>

                <FormGroup mb="20px">
                  <Input
                    name="f1_orgnum"
                    id="f1_orgnum"
                    type="text"
                    placeholder={lang==="en"?"Your name *":"Organisasjonsnummer *"}
                    hover="2"
                    ref={register({ required: (lang==="en"?"Name is required":'Organisasjonsnummer er påkrevd') })}
                  />
                  <Error>{errors.f1_orgnum && errors.f1_orgnum.message}</Error>
                </FormGroup>


                <FormGroup mb="20px">
                  <Select
                    name="f1_type"
                    id="f1_type"
                    hover="1"
                    ref={register({
                          required: (lang==="en"?"Type is required":'Type garantiforespørsel er påkrevd. **Merk at øvrige garantityper ikke er tilgjengelig.'),
                          pattern: {
                            value: /[a-zA-Z0-9\. ]/i,
                            message: (lang === "en"?"Invalid mobile number":'Ugyldig garantitype. Merk at øvrige garantityper ikke er tilgjengelige i dagens marked.')
                          }
                    })}
                  >
                    <option value={""}> - - Velg type garanti som ønskes - - </option>
                    <option value={"Byggherregaranti"}>Byggherregaranti</option>
                    <option value={"Byggsikkerhetsgaranti"}>Byggsikkerhetsgaranti</option>
                    <option value={"Forskuddsgaranti"}>Forskuddsgaranti</option>
                    <option value={"Utførelsesgaranti"}>Utførelsesgaranti</option>
                    <option value={""}>Øvrige garantityper**</option>
                  </Select>
                  <Error>{errors.f1_type && errors.f1_type.message}</Error>
                </FormGroup>

                <FormGroup mb="20px">
                    <Input
                        name="f1_phone"
                        id="f1_phone"
                        type="phone"
                        placeholder={lang==="en"?"Mobile number *":"Mobilnummer til kontaktperson *"}
                        hover="2"
                        ref={register({
                            required: (lang === "en"?"Mobile number is required":'Mobilnummer er påkrevd'),
                            pattern: {
                                value: /^(|\+)[0-9]{8,15}$/i,
                                message: (lang === "en"?"Invalid mobile number":'Ugyldig mobilnummer')
                            }
                        })}
                    />
                    <Error>{errors.f1_phone && errors.f1_phone.message}</Error>
                </FormGroup>

                <FormGroup mb="20px">
                    <Input
                        name="f1_email"
                        id="f1_email"
                        type="email"
                        placeholder={lang==="en"?"E-mail *":"E-post til kontaktperson *"}
                        hover="2"
                        ref={register({
                            required: (lang === "en"?"E-mail is required":'E-post er påkrevd'),
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                              message: (lang === "en"?"Invalid e-mail address":'Ugyldig e-postadresse')
                            }
                        })}
                    />
                    <Error>{errors.f1_email && errors.f1_email.message}</Error>
                </FormGroup>

              <FormGroup mb="20px">
                <Textarea
                  name="f1_desc"
                  id="f1_desc"
                  placeholder={lang==="en"?"E-mail *":"Beskrivelse av bedriftens garantibehov *"}
                  hover="2"
                  ref={register({ required: (lang==="en"?"Name is required":'Beskrivelse av det aktuelle garantibehovet er påkrevd') })}
                />
                <Error>{errors.f1_desc && errors.f1_desc.message}</Error>
              </FormGroup>



                <FormGroup textalign="center">
                    <Button
                        type="submit"
                        pl="54px"
                        pr="54px"
                        hover="2">Send &raquo;</Button>
                </FormGroup>


            </Form>}
            {waiting &&
              <div>
                Sender inn skjemadata...
              </div>
            }
            {formSubmitted &&
              <>
                {formError &&
                  <div style={{color:'red'}}>
                    Det har oppstått en feil. Du kan i steden ringe oss på 452 72 590.
                  </div>}
                {!formError &&
                  <div>
                    Skjemaet ble sendt inn. Vi tar kontakt dersom vi har ytterligere spørsmål og når vi har et tilbud klart.
                  </div>}
              </>
            }
        </ModalForm>
    )
}


export default ProductForm;
