import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import './modal.scss'

const ModalForm = ({children,setFormActive,formActive,formTitle,icon}) => {

    return(
        <div className="modal-form-wrapper">
            <Modal
                show={formActive}
                onHide={() => {setFormActive(false)}}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <div className="titleWrapper">
                            <div className="iconWrapper">{icon}</div>
                            <div className="textWrapper">{formTitle}</div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalForm;
