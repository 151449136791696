import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser'
import {Container, Row, Col} from 'react-bootstrap'
import {FooterWrap, FooterInner, FooterContent, ButtonWrap} from './footer.style'
import Heading from '../../../../components/ui/heading'
import Button from '../../../../components/ui/button'

const Footer = ({heading, subheading, product, setFormActive}) => {
    const footerData = useStaticQuery(graphql `
        query FooterDataQuery{
            landingJson(id: {eq: "preview-footer-data"}) {
                title
                subtitle
                path
            }
            file(relativePath: {eq: "images/bg/mitech-landing-footer-bg-01.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 628, quality: 100) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    const {title, subtitle} = footerData.landingJson;
    const bgImage = footerData.file.childImageSharp.fluid;
    return(
        <FooterWrap>
            <FooterInner fluid={bgImage}>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <FooterContent>
                                {title && <Heading {...heading}>Få uforpliktende tilbud på {(product)?product.toLowerCase():"garanti"}er fra flere ulike leverandører</Heading>}
                                {subtitle && <Heading {...subheading}>Frigjør kapital og spar penger på virksomhetens garantier</Heading>}
                                <ButtonWrap>
                                    <Button
                                        onClick={()=>{setFormActive(true)}}
                                        skin="light">Få tilbud nå &raquo;</Button>
                                </ButtonWrap>
                            </FooterContent>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{textAlign:'center',color:'white',fontSize:'110%'}}>
                            <p>
                                Garantitilbud.no er en tjeneste som innhenter informasjon om bedrifters garantibehov - og som basert på dette og offentlig
                                tilgjengelig informasjon, på vegne av den potensielle garantitaker, spør aktører i markedet om tilbud for det oppgitte garantibehovet.
                                Alle innhentede tilbud blir uavkortet videreformidlet til den potensielle garantitaker, som så egenhendig kan gjøre en vurdering om eventuelt kjøp.
                                Nettsidene driftes av selskapet Audere As. Tjenesten tilbyr ikke rådgivning - og skal ikke brukes som, forstås som eller erstatte noen form for
                                rådgivning.

                            </p>
                        </Col>
                    </Row>
                </Container>
            </FooterInner>
        </FooterWrap>
    )
}

Footer.propTypes = {
    heading: PropTypes.object,
    subheading: PropTypes.object
}

Footer.defaultProps = {
    heading: {
        color: '#fff'
    },
    subheading: {
        as: 'h6',
        color: '#fff',
        letterSpacing: '7px',
        mt: '25px'
    }
}

export default Footer;
