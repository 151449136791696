import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Text from '../../../components/ui/text'
import Button from '../../../components/ui/button'
import {Container, Row, Col} from '../../../components/ui/wrapper'
import SectionTitle from '../../../components/ui/section-title'
import NumberedList, {NumberedListItem} from '../../../components/ui/numbered-list'
import {SectionWrap, LeftBox} from './about-area.style'


const AboutArea = (props) => {
    const aboutDataQuery = useStaticQuery(graphql `
        query infotechnoAboutQueryData {
            infotechnoJson(id: {eq: "infotechno-about-content"}) {
                title
                subtitle
                desc
                path
                features {
                  text
                  path
                }
            }
            
        providers:   allSanityGuaranteeProvider {
            edges {
              node {
                bygg
                _rawContent(resolveReferences: {maxDepth: 15})
                can_use_logo
                slug {
                  current
                }
                name
                homepage
                                    logo{
                      asset{
                          url
                          fluid(maxWidth: 700) {
                              srcWebp
                              srcSetWebp
                              srcSet
                              src
                              sizes
                              base64
                              aspectRatio
                          }                    
                      }
                    }
                    
                                        image{
                      asset{
                          url
                          fluid(maxWidth: 700) {
                              srcWebp
                              srcSetWebp
                              srcSet
                              src
                              sizes
                              base64
                              aspectRatio
                          }                    
                      }
                    }
                introduction
              }
            }
          }
            
        }
    `);

    //console.log(aboutDataQuery.providers.edges)

    const {title, subtitle, path, desc, features} = aboutDataQuery.infotechnoJson;
    const {sectionTitleStyle, descStyle, btnStyle, industries,products} = props
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={5}>
                        <LeftBox>
                            <SectionTitle
                                {...sectionTitleStyle}
                                title={"Hvilke ulike typer <span>garantiforsikringer</span> er aktuelle for din bedrift?"}
                                subtitle={"Usikker på garantiforsikringer?"}
                            />
                            {desc && <Text {...descStyle}>
                                Næringsdrivende som omfattes av regulatoriske krav om garantier og / eller
                                kontraktsmessige krav om garantistillelse bør sette seg inn i hvordan en
                                forsikringsgaranti fungerer og hvilke fordeler den har kontra en tradisjonell
                                garanti fra bank.
                            </Text>}
                            {path && <Button to={"/hva-er-en-garantiforsikring"} {...btnStyle}>Hvordan fungerer en garantiforsikring?</Button>}

                            {/*<br/><br/><br/>

                            <SectionTitle
                              {...sectionTitleStyle}
                              title={"Leverandører av <span>garantiforsikringer</span>"}
                              subtitle={""}
                            />


                                {aboutDataQuery.providers.edges.map((provider,j) => {
                                    //console.log(provider)
                                    return(
                                        <>
                                            {j > 0 && ", "}
                                            <a href={"/"+provider.node.slug.current} key={j+'priv'}>{provider.node.name}</a>
                                        </>
                                    )
                                  }
                                )}
                            */}
                        </LeftBox>
                    </Col>
                    <Col lg={5} ml="auto">
                        <SectionTitle
                          {...sectionTitleStyle}
                          title={"Ulike leverandører av <span>garantiforsikringer</span> i det norske markedet:"}
                          subtitle={""}
                        />


                        {aboutDataQuery.providers.edges.map((provider,j) => {
                              //console.log(provider)
                              return(
                                <span key={"s"+j}>
                                    {j > 0 && ", "}
                                    <a href={"/"+provider.node.slug.current} key={j+'priv'}>{provider.node.name}</a>
                                </span>
                              )
                          }
                        )}

                        <div style={{marginTop: '20px'}}>
                          <strong>
                            Disse eksempelaktørene er blant dem som potensielt kan tilby
                            behovspesifikke garantiforsikringer i det norske markedet.
                          </strong>
                        </div>
                        {/*<SectionTitle
                          {...sectionTitleStyle}
                          title={"Bransjeguide og <span>ulike garantityper</span>"}
                          subtitle={"Hvilke garantier er aktuelle"}
                        />
                        <br/>
                        <NumberedList>
                            {industries.edges.map((industry, i) => {
                                //console.log(industry)
                                if(industry.node.bygg && industry.node.bygg === true){
                                    return(
                                        <NumberedListItem key={`feat-${i}`} path={'/bransje/' + industry.node.slug.current}
                                                          number={i + 1}>{industry.node.name}</NumberedListItem>
                                        )
                                }
                            })}
                        </NumberedList>*/}
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

AboutArea.propTypes = {
    descStyle: PropTypes.object,
    btnStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object
}

AboutArea.defaultProps = {
    sectionTitleStyle: {
        mb: '20px',
        align: 'left'
    },
    descStyle: {
        mb: '30px'
    },
    btnStyle: {
        hover: "2"
    }
}

export default AboutArea;
